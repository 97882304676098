<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas Regalias</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Rutas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!--                       <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_rutasRegalias_export"
                        v-if="$store.getters.can('admin.ecopetrolRutas.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button> -->
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-rutaEcopetrol"
                        @click="abrirModal('Crear', null)"
                        v-if="$store.getters.can('admin.ecopetrolRutas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="rutas"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre Ruta
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label>Origen</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.slct_origen"
                          placeholder="Origen"
                          label="producto_sitio"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.origenes"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        <label>Destino</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.slct_destino"
                          placeholder="Destino"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.destinos"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th style="width: 150px">
                        <label>Código</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.codigo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @input="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="width: 50px"><label>Accion</label></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ruta in rutas.data" :key="ruta.id">
                      <td>{{ ruta.nombre }}</td>
                      <td v-if="ruta.origen">
                        {{
                          ruta.origen.nombre +
                            " - " +
                            "(" +
                            ruta.origen.sitio.nombre +
                            ")"
                        }}
                      </td>
                      <td v-else></td>
                      <td v-if="ruta.destino">{{ ruta.destino.nombre }}</td>
                      <td v-else></td>
                      <td>{{ ruta.codigo }}</td>
                      <td class="text-center">
                        <span
                          :class="
                            ruta.estado == 1
                              ? 'badge bg-success'
                              : 'badge bg-danger'
                          "
                        >
                          {{ ruta.nEstado }}</span
                        >
                      </td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-rutaEcopetrol"
                            v-if="
                              $store.getters.can('admin.ecopetrolRutas.edit')
                            "
                            @click="abrirModal('Editar', ruta.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="rutas.total">
                  <p>
                    Mostrando del <b>{{ rutas.from }}</b> al
                    <b>{{ rutas.to }}</b> de un total:
                    <b>{{ rutas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger"
                      >No hay registros para mostrar</span
                    >
                  </p>
                </div>
                <pagination
                  :data="rutas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right m-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Modal Crear editar -->
    <div
      class="modal fade"
      id="modal-form-rutaEcopetrol"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="limpiar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="origen">Origen</label>
              <v-select
                :class="[
                  $v.objRutaEcopetrol.origen_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="slct_origen"
                :options="listasForms.origenes"
                placeholder="Origen"
                label="producto_sitio"
                class="form-control form-control-sm p-0"
                @input="actualizarNombreRuta()"
              />
            </div>
            <div class="form-group">
              <label for="destino">Destino</label>
              <v-select
                :class="[
                  $v.objRutaEcopetrol.destino_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="slct_destino"
                placeholder="Destino"
                label="nombre"
                class="form-control form-control-sm p-0"
                :options="listasForms.destinos"
                @input="actualizarNombreRuta"
              ></v-select>
            </div>
            <div class="form-group">
              <label for="codigo">Código</label>
              <input
                :class="[
                  $v.objRutaEcopetrol.codigo.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                type="text"
                class="form-control form-control-sm"
                v-model="objRutaEcopetrol.codigo"
              />
            </div>
            <div class="form-group">
              <label for="vigencia">Vigencia <small>Horas</small></label>
              <input
                :class="[
                  $v.objRutaEcopetrol.horas_vigencia.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                type="number"
                min="0"
                class="form-control form-control-sm"
                v-model="objRutaEcopetrol.horas_vigencia"
              />
            </div>
            <div class="form-group">
              <label for="nombre">Nombre</label>
              <input
                :class="[
                  $v.objRutaEcopetrol.nombre.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                type="text"
                class="form-control form-control-sm"
                v-model="objRutaEcopetrol.nombre"
                readonly
              />
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                :class="[
                  $v.objRutaEcopetrol.estado.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  'form-control form-control-sm',
                ]"
                v-model="objRutaEcopetrol.estado"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                  :disabled="modal.accion == 'Crear' && estado.numeracion == 2"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between p-1"
            v-if="!$v.objRutaEcopetrol.$invalid"
          >
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <RutaEcopetrolExport ref="modal_form_rutasRegalias_export" />
  </div>
</template>
<script>
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required, minValue } from "vuelidate/lib/validators";
import RutaEcopetrolExport from "./RutaEcopetrolExport";

export default {
  name: "RutaRegaliasIndex",
  components: {
    pagination,
    Loading,
    vSelect,
    RutaEcopetrolExport,
  },
  data() {
    return {
      cargando: false,
      rutas: {},
      filtros: {
        nombre: null,
        codigo: null,
        origen_id: null,
        destino_id: null,
        estado: null,
      },
      listasForms: {
        sitiosOrigen: [],
        tipoRutas: [],
        despachados: [],
        origenes: [],
        destinos: [],
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      objRutaEcopetrol: {
        nombre: "",
        origen_id: null,
        destino_id: null,
        codigo: null,
        horas_vigencia: null,
        estado: null,
      },
      origenes: [],
      destinos: [],
      slct_origen: {},
      slct_destino: {},
    };
  },
  validations: {
    objRutaEcopetrol: {
      nombre: {
        required,
      },
      codigo: {
        required,
      },
      destino_id: {
        required,
      },
      origen_id: {
        required,
      },
      horas_vigencia: {
        required,
        minValue: minValue(0),
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      this.filtros.origen_id = null;
      if (this.filtros.slct_origen) {
        this.filtros.origen_id = this.filtros.slct_origen.id;
      }

      this.filtros.destino_id = null;
      if (this.filtros.slct_destino) {
        this.filtros.destino_id = this.filtros.slct_destino.id;
      }
      await axios
        .get("/api/admin/rutasEcopetrol?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.rutas = response.data;
          this.cargando = false;
        });
    },

    async getOrigenes() {
      await axios
        .get("api/hidrocarburos/Productos/lista?estado=1", {
          params: {
            ruta_regalias: 1,
          },
        })
        .then((response) => {
          this.listasForms.origenes = response.data;
        });
    },

    async getDestinos() {
      await axios
        .get("api/admin/destinosEcopetrol/lista?estado=1")
        .then((response) => {
          this.listasForms.destinos = response.data;
        });
    },

    async getEstado() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    actualizarNombreRuta() {
      const origen = this.slct_origen ? this.slct_origen.producto_sitio : "";
      const destino = this.slct_destino ? this.slct_destino.nombre : "";
      this.objRutaEcopetrol.nombre =
        origen && destino ? `${origen} - ${destino}` : "";
      this.objRutaEcopetrol.origen_id = this.slct_origen
        ? this.slct_origen.id
        : "";
      this.objRutaEcopetrol.destino_id = this.slct_destino
        ? this.slct_destino.id
        : "";
    },

    async abrirModal(accion, idRutaEcopetrol) {
      this.modal.title = `${accion} Ruta Regalias`;
      this.modal.accion = accion;
      if (accion == "Crear") {
        this.objRutaEcopetrol = {
          nombre: "",
          origen_id: null,
          destino_id: null,
          codigo: null,
          estado: 1,
        };
      } else if (accion == "Editar") {
        await this.show(idRutaEcopetrol);
      }
    },

    async show(idRutaEcopetrol) {
      this.cargando = true;
      await axios
        .get("/api/admin/rutasEcopetrol/show/" + idRutaEcopetrol)
        .then((response) => {
          this.objRutaEcopetrol = response.data;
          this.slct_origen = this.listasForms.origenes.find(
            (ori) => ori.id === this.objRutaEcopetrol.origen_id
          );
          this.slct_destino = this.listasForms.destinos.find(
            (dest) => dest.id === this.objRutaEcopetrol.destino_id
          );
          this.cargando = false;
        });
      this.cargando = false;
    },

    async save() {
      if (!this.$v.objRutaEcopetrol.$invalid) {
        this.cargando = true;
        await axios
          .post("/api/admin/rutasEcopetrol", this.objRutaEcopetrol)
          .then(async (response) => {
            this.$refs.closeModal.click();
            this.objRutaEcopetrol = {};
            await this.getIndex(this.page);
            await this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    limpiar() {
      this.objRutaEcopetrol = {};
      this.slct_origen = null;
      this.slct_destino = null;
    },

    export() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/rutasEcopetrol/export",
        data: { form: this.form, check: this.check },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getOrigenes();
    await this.getDestinos();
    await this.getEstado();
  },
};
</script>
